<template>
  <div class="authorization-form" :class="platform" data-t="authorization-form">
    <KeepAlive :max="5">
      <FirstStep
        v-if="step === 0"
        @success="handleFirstStepSuccess"
        @login="handleSuccess"
      />
      <SecondStep
        v-else-if="step === 1"
        :data="authData"
        @success="handleSuccess"
        @back="goToFirstStep"
      />
    </KeepAlive>
  </div>
</template>

<script setup lang="ts">
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'

interface AuthorizationData {
  email: string
  password: string
}

const { platform } = usePlatform()

const router = useRouter()

const step = ref(0)
const authData = ref<AuthorizationData>({
  email: '',
  password: '',
})

function handleFirstStepSuccess(data: AuthorizationData) {
  step.value += 1
  authData.value = data
}

function goToFirstStep() {
  step.value -= 1
}

const route = useRoute()

function handleSuccess() {
  router.replace({ query: {} })
  if (route.path.includes('promo/registration')) {
    router.push('/')
  }
}
</script>

<style scoped>
.authorization-form {
  display: flex;
  width: 480px;
  min-height: 444px;
  padding: var(--spacing-500) var(--spacing-600);

  &.mobile {
    width: 100%;
    height: 100%;
    padding: var(--spacing-200);
    padding-top: 0;
  }
}

.header-text {
  margin: 0;
}
</style>
